import { FC, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import Select from "@albertsons/uds/molecule/Select";
import Tooltip from "@albertsons/uds/molecule/Tooltip";
import { Eraser } from "lucide-react";
import useWindowBreakpoints from "../../../hooks/useWindowBreakpoints";
import { AppDispatch, RootState } from "../../../store";
import {
  changeDashboardFilters,
  fetchFilterOptions,
  fetchProductDescription,
  fetchCICAndUPCDetails,
} from "../../../store/modules/dashboard";
import {
  resetExpandModeDCStoreFilter,
  resetExpandModeStoreOrderFilter,
} from "../../../store/modules/orderHistory";
import { LoadingIndicator } from "../../../components";
import {
  defaultFiltersLoaded,
  defaultFilterOptions,
} from "../../../constants/filters";
import {
  FilterType,
  IFilterLoaded,
  IFilterOptions,
} from "../../../types/filters.types";
import { IFilterOption } from "../../../types/dropdown.types";
import { BarcodeType, BarcodeValue } from "../../../types/barcode.types";
import "./styles.css";

interface IFiltersProps {
  forceScreen?: "extraSmall" | "mobile" | "desktop";
  showWarehouseDivision?: boolean;
  showDistributionCenter?: boolean;
  showWarehouse?: boolean;
  showDSDVendor?: boolean;
  showFullUPC?: boolean;
  filterOptionsLoading: IFilterLoaded;
  dashboardFilters: IFilterOptions;
  filterOptions: IFilterOptions;
  productDescription: string;
  setDashboardFilters: (filters: IFilterOptions) => void;
  fetchFilterOptions: (
    type: string,
    query: string,
    filters: IFilterOptions
  ) => void;
  fetchProductDescription: (cic: IFilterOption[]) => void;
  fetchCICAndUPCDetails: (data: string, type: BarcodeType) => void;
  resetExpandModeDCStoreFilters: () => void;
  resetExpandModeStoreOrderFilters: () => void;
  floatingResetKey: number;
  filtersResetKey: number;
  filtersUpdateKey: number;
  barcodeValue: BarcodeValue | null;
  resetBarcodeValue: () => void;
}

const EraserIcon = ({ onClear }: { onClear: () => void }) => (
  <div className="invisible">
    <Tooltip zIndex={20} anchor="top">
      <Tooltip.Popover>
        <span className="text-md m-2">Clear Selections</span>
      </Tooltip.Popover>
      <Eraser
        className="bg-[#e6e6e6] cursor-pointer"
        size={12}
        onClick={onClear}
      />
    </Tooltip>
  </div>
);

export const Filters: FC<IFiltersProps> = (props) => {
  const {
    forceScreen,
    showWarehouseDivision = false,
    showDistributionCenter = false,
    showWarehouse = false,
    showDSDVendor = false,
    showFullUPC = false,
    filterOptionsLoading,
    dashboardFilters,
    filterOptions,
    productDescription,
    setDashboardFilters,
    fetchFilterOptions,
    fetchProductDescription,
    fetchCICAndUPCDetails,
    floatingResetKey,
    filtersResetKey,
    filtersUpdateKey,
    barcodeValue,
    resetBarcodeValue,
    resetExpandModeDCStoreFilters,
    resetExpandModeStoreOrderFilters,
  } = props;

  const [selectedFilterOptions, setSelectedFilterOptions] =
    useState<IFilterOptions>(defaultFilterOptions);

  const { getScreen } = useWindowBreakpoints();
  const screen = getScreen(forceScreen);

  const filterLoadStateRef = useRef<IFilterLoaded>({ ...defaultFiltersLoaded });
  const focusedFiltersRef = useRef<FilterType[]>([]);
  const updatedFiltersRef = useRef(false);

  const getSelectionPlaceholder = (fieldName: FilterType) => {
    const selectedOptions = selectedFilterOptions[fieldName];
    const allOptions = filterOptions[fieldName];
    if (
      !selectedOptions ||
      selectedOptions.length === 0 ||
      allOptions.length === selectedOptions.length
    ) {
      return "All";
    }
    if (selectedOptions.length === 1) {
      if ((fieldName === "upc" || fieldName === "cic") && screen.large) {
        return selectedOptions[0].description;
      }
      return selectedOptions[0].label;
    }
    return "Multiple selections";
  };

  const onFocusedFilter = (fieldName: FilterType) => {
    if (!focusedFiltersRef.current.includes(fieldName)) {
      focusedFiltersRef.current = [...focusedFiltersRef.current, fieldName];
      if (!filterLoadStateRef.current[fieldName]) {
        filterLoadStateRef.current[fieldName] = true;
        fetchFilterOptions(fieldName, "", dashboardFilters);
      }
    }
  };

  const onBlurFilter = (fieldName: FilterType) => {
    if (focusedFiltersRef.current.includes(fieldName)) {
      focusedFiltersRef.current = focusedFiltersRef.current.filter(
        (field) => field !== fieldName
      );
    }
  };

  const resetOrderHistoryFilters = () => {
    resetExpandModeDCStoreFilters();
    resetExpandModeStoreOrderFilters();
  };

  const handleSelectChange = (
    items: IFilterOption[],
    fieldName: FilterType,
    clearFieldName?: FilterType
  ) => {
    filterLoadStateRef.current = {
      ...defaultFiltersLoaded,
      [fieldName]: true,
    };
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      ...(clearFieldName ? { [clearFieldName]: [] } : {}),
      [fieldName]: items,
    });
    updatedFiltersRef.current = true;

    if (fieldName === "cic") {
      fetchProductDescription(items);
    }
  };

  const resetCICandUPC = () => {
    filterLoadStateRef.current = { ...defaultFiltersLoaded };
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      upc: defaultFilterOptions.upc,
      cic: defaultFilterOptions.cic,
    });
    updatedFiltersRef.current = true;
    fetchProductDescription([]);
  };

  const resetFilter = (fieldName: FilterType) => {
    filterLoadStateRef.current = { ...defaultFiltersLoaded };
    setSelectedFilterOptions({
      ...selectedFilterOptions,
      [fieldName]: [],
    });
    updatedFiltersRef.current = true;

    if (fieldName === "cic") {
      fetchProductDescription([]);
    }
  };

  const clearAll = () => {
    filterLoadStateRef.current = { ...defaultFiltersLoaded };
    setSelectedFilterOptions(defaultFilterOptions);
    updatedFiltersRef.current = true;
    fetchProductDescription([]);
  };

  useEffect(() => {
    if (barcodeValue) {
      filterLoadStateRef.current.cic = true;
      filterLoadStateRef.current.upc = true;
      fetchCICAndUPCDetails(barcodeValue.data, barcodeValue.type);
      resetOrderHistoryFilters();
      resetBarcodeValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barcodeValue]);

  useEffect(() => {
    if (floatingResetKey) {
      clearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floatingResetKey]);

  useEffect(() => {
    if (filtersResetKey) {
      filterLoadStateRef.current = { ...defaultFiltersLoaded };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersResetKey]);

  useEffect(() => {
    if (updatedFiltersRef.current) {
      updatedFiltersRef.current = false;
      setDashboardFilters(selectedFilterOptions);
      resetOrderHistoryFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOptions]);

  useEffect(() => {
    if (filtersUpdateKey) {
      setSelectedFilterOptions(dashboardFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersUpdateKey]);

  return (
    <>
      <div
        className={clsx(screen.large && "bg-[#d9d9d9] py-5 px-2")}
        key={screen.small ? "filters-small" : "filters-large"}
      >
        <div
          className={clsx(
            "grid",
            screen.small ? "grid-cols-2" : "grid-cols-1 gap-0 w-[210px]"
          )}
        >
          {showWarehouseDivision && (
            <div
              className={clsx(
                "relative",
                screen.small
                  ? "border-[1px] border-solid border-black order-1 px-2 pb-2"
                  : "order-1 last:[&>div>div]:hover:!visible"
              )}
            >
              <div
                className={clsx(
                  "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                  screen.large && "mx-2 pr-2"
                )}
              >
                <div className="text-xs font-['Arial']">Warehouse Division</div>
                {screen.large && (
                  <EraserIcon
                    onClear={() => resetFilter("warehouseDivision")}
                  />
                )}
              </div>
              {filterOptionsLoading.warehouseDivision && <LoadingIndicator />}
              <Select<IFilterOption>
                className={clsx(
                  "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                  screen.small ? "w-full" : "ml-[7px] w-[93%]"
                )}
                placeholder="All"
                selectionPlaceholder={getSelectionPlaceholder(
                  "warehouseDivision"
                )}
                id="warehouse-division"
                items={filterOptions.warehouseDivision}
                itemText="label"
                itemKey="value"
                multiple
                checkboxes
                search
                size="md"
                selectAllOption
                noTags
                value={selectedFilterOptions.warehouseDivision}
                onClick={() => onFocusedFilter("warehouseDivision")}
                onClose={() => onBlurFilter("warehouseDivision")}
                onChange={(items) =>
                  handleSelectChange(items, "warehouseDivision")
                }
                options={{
                  modifiers: [
                    { name: "preventOverflow", enabled: screen.large },
                  ],
                }}
              />
            </div>
          )}
          {showWarehouse && (
            <div
              className={clsx(
                "relative",
                screen.small
                  ? "border-[1px] border-solid border-black order-2 px-2 pb-2"
                  : "order-3 last:[&>div>div]:hover:!visible"
              )}
            >
              <div
                className={clsx(
                  "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                  screen.large && "mx-2 pr-2"
                )}
              >
                <div className="text-xs font-['Arial']">Warehouse</div>
                {screen.large && (
                  <EraserIcon onClear={() => resetFilter("warehouse")} />
                )}
              </div>
              {filterOptionsLoading.warehouse && <LoadingIndicator />}
              <Select<IFilterOption>
                className={clsx(
                  "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                  screen.small ? "w-full" : "ml-[7px] w-[93%]"
                )}
                placeholder="All"
                selectionPlaceholder={getSelectionPlaceholder("warehouse")}
                id="warehouse"
                items={filterOptions.warehouse}
                itemText="label"
                itemKey="value"
                multiple
                checkboxes
                search
                size="md"
                selectAllOption
                noTags
                value={selectedFilterOptions.warehouse}
                onClick={() => onFocusedFilter("warehouse")}
                onClose={() => onBlurFilter("warehouse")}
                onChange={(items) => handleSelectChange(items, "warehouse")}
                options={{
                  modifiers: [
                    { name: "preventOverflow", enabled: screen.large },
                  ],
                }}
              />
            </div>
          )}
          {showDistributionCenter && screen.large && (
            <div
              className={clsx(
                "relative",
                screen.small
                  ? "border-[1px] border-solid border-black order-3 px-2 pb-2"
                  : "order-2 last:[&>div>div]:hover:!visible"
              )}
            >
              <div
                className={clsx(
                  "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                  screen.large && "mx-2 pr-2"
                )}
              >
                <div className="text-xs font-['Arial']">
                  Distribution Center
                </div>
                {screen.large && (
                  <EraserIcon onClear={() => resetFilter("distribution")} />
                )}
              </div>
              {filterOptionsLoading.distribution && <LoadingIndicator />}
              <Select<IFilterOption>
                className={clsx(
                  "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                  screen.large && "w-[93%] ml-[7px]",
                  screen.small && "w-full"
                )}
                placeholder="All"
                selectionPlaceholder={getSelectionPlaceholder("distribution")}
                items={filterOptions.distribution}
                id="distribution-center"
                itemText="label"
                itemKey="value"
                multiple
                checkboxes
                search
                size="md"
                selectAllOption
                noTags
                value={selectedFilterOptions.distribution}
                onClick={() => onFocusedFilter("distribution")}
                onClose={() => onBlurFilter("distribution")}
                onChange={(items) => handleSelectChange(items, "distribution")}
                options={{
                  modifiers: [
                    { name: "preventOverflow", enabled: screen.large },
                  ],
                }}
              />
            </div>
          )}
          {showDSDVendor && (
            <div
              className={clsx(
                "relative",
                screen.small
                  ? "border-[1px] border-solid border-black order-3 px-2 pb-2"
                  : "order-2 last:[&>div>div]:hover:!visible"
              )}
            >
              <div
                className={clsx(
                  "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                  screen.large && "mx-2 pr-2"
                )}
              >
                <div className="text-xs font-['Arial']">DSD Vendor</div>
                {screen.large && (
                  <EraserIcon onClear={() => resetFilter("dsdVendor")} />
                )}
              </div>
              {filterOptionsLoading.dsdVendor && <LoadingIndicator />}
              <Select<IFilterOption>
                className={clsx(
                  "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                  screen.large && "w-[93%] ml-[7px]",
                  screen.small && "w-full"
                )}
                placeholder="All"
                selectionPlaceholder={getSelectionPlaceholder("dsdVendor")}
                items={filterOptions.dsdVendor}
                id="dsd-vendor"
                itemText="label"
                itemKey="value"
                multiple
                checkboxes
                search
                size="md"
                selectAllOption
                noTags
                value={selectedFilterOptions.dsdVendor}
                onClick={() => onFocusedFilter("dsdVendor")}
                onClose={() => onBlurFilter("dsdVendor")}
                onChange={(items) => handleSelectChange(items, "dsdVendor")}
                options={{
                  modifiers: [
                    { name: "preventOverflow", enabled: screen.large },
                  ],
                }}
              />
            </div>
          )}
          <div
            className={clsx(
              "relative",
              "order-4",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible"
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">Retail Division</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("retailDivision")} />
              )}
            </div>
            {filterOptionsLoading.retailDivision && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "ml-[7px] w-[93%]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("retailDivision")}
              items={filterOptions.retailDivision}
              id="retailDivision"
              itemText="label"
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.retailDivision}
              onClick={() => onFocusedFilter("retailDivision")}
              onClose={() => onBlurFilter("retailDivision")}
              onChange={(items) => handleSelectChange(items, "retailDivision")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>

          <div
            className={clsx(
              "relative",
              "order-5",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible"
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">Retail Store</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("retailStore")} />
              )}
            </div>
            {filterOptionsLoading.retailStore && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "w-[93%] ml-[7px]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("retailStore")}
              id="retailStore"
              items={filterOptions.retailStore}
              itemText="label"
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.retailStore}
              onClick={() => onFocusedFilter("retailStore")}
              onClose={() => onBlurFilter("retailStore")}
              onChange={(items) => handleSelectChange(items, "retailStore")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>

          <div
            className={clsx(
              "relative",
              "order-6",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible"
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">Department</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("department")} />
              )}
            </div>
            {filterOptionsLoading.department && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "w-[93%] ml-[7px]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("department")}
              items={filterOptions.department}
              id="department"
              itemText="label"
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.department}
              onClick={() => onFocusedFilter("department")}
              onClose={() => onBlurFilter("department")}
              onChange={(items) => handleSelectChange(items, "department")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>

          <div
            className={clsx(
              "relative",
              "order-7",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible"
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">Retail Section</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("retailSection")} />
              )}
            </div>
            {filterOptionsLoading.retailSection && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "w-[93%] ml-[7px]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("retailSection")}
              items={filterOptions.retailSection}
              id="retailSection"
              itemText="label"
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.retailSection}
              onClick={() => onFocusedFilter("retailSection")}
              onClose={() => onBlurFilter("retailSection")}
              onChange={(items) => handleSelectChange(items, "retailSection")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>

          <div
            className={clsx(
              "relative",
              "order-8",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible"
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">SMIC</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("smic")} />
              )}
            </div>
            {filterOptionsLoading.smic && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "ml-[7px] w-[93%]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("smic")}
              id="smic"
              items={filterOptions.smic}
              itemText="label"
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.smic}
              onClick={() => onFocusedFilter("smic")}
              onClose={() => onBlurFilter("smic")}
              onChange={(items) => handleSelectChange(items, "smic")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>

          <div
            className={clsx(
              "relative",
              "bg-[#f4d8d8] order-9",
              screen.small
                ? "border-[1px] border-solid border-black px-2 pb-2"
                : "last:[&>div>div]:hover:!visible",
              screen.small && showFullUPC ? "col-span-2" : ""
            )}
          >
            <div
              className={clsx(
                "my-2 flex justify-between items-center group bg-[#e6e6e6]",
                screen.large && "mx-2 pr-2"
              )}
            >
              <div className="text-xs font-['Arial']">UPC</div>
              {screen.large && (
                <EraserIcon onClear={() => resetFilter("upc")} />
              )}
            </div>
            {filterOptionsLoading.upc && <LoadingIndicator />}
            <Select<IFilterOption>
              className={clsx(
                "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
                screen.large && "ml-[7px] w-[93%]",
                screen.small && "w-full"
              )}
              placeholder="All"
              selectionPlaceholder={getSelectionPlaceholder("upc")}
              id="upc"
              items={filterOptions.upc}
              itemText={screen.small ? "label" : "description"}
              itemKey="value"
              multiple
              checkboxes
              search
              size="md"
              selectAllOption
              noTags
              value={selectedFilterOptions.upc}
              onClick={() => onFocusedFilter("upc")}
              onClose={() => onBlurFilter("upc")}
              onChange={(items) => handleSelectChange(items, "upc")}
              options={{
                modifiers: [{ name: "preventOverflow", enabled: screen.large }],
              }}
            />
          </div>
        </div>

        <div
          className={clsx(
            "mb-2 pt-1 bg-[#f4d8d8] order-10 relative",
            screen.small
              ? "border-[1px] border-solid border-black px-2 pb-2"
              : "h-[84px] last:[&>div>div]:hover:!visible w-[210px]"
          )}
        >
          <div
            className={clsx(
              "my-2 flex justify-between items-center group bg-[#e6e6e6]",
              screen.large && "mx-2 pr-2"
            )}
          >
            <div className="text-xs font-['Arial']">CIC</div>
            {screen.large && <EraserIcon onClear={() => resetFilter("cic")} />}
          </div>
          {filterOptionsLoading.cic && <LoadingIndicator />}
          <Select<IFilterOption>
            className={clsx(
              "mt-[2px] border border-[#e5e7eb] px-3 text-sm",
              screen.small ? "w-full" : "ml-[7px] w-[93%]"
            )}
            placeholder="All"
            id="cic"
            items={filterOptions.cic}
            itemText={screen.small ? "label" : "description"}
            itemKey="value"
            search
            size="md"
            noTags
            value={
              selectedFilterOptions.cic.length > 0
                ? selectedFilterOptions.cic[0]
                : undefined
            }
            onClick={() => onFocusedFilter("cic")}
            onClose={() => onBlurFilter("cic")}
            onChange={(item) => handleSelectChange([item], "cic")}
            options={{
              modifiers: [{ name: "preventOverflow", enabled: screen.large }],
            }}
          />
        </div>
      </div>
      {screen.small && (
        <>
          <div
            className={clsx(
              "font-['Arial'] grid grid-cols-2",
              screen.isMobile && "text-xs"
            )}
          >
            <button
              type="button"
              className="border-solid border-[1.5px] border-black rounded-full py-2 px-4 mr-3 ml-5 w-fit"
              onClick={resetCICandUPC}
              data-testid="reset"
            >
              Reset CIC/UPC
            </button>

            <button
              type="button"
              className="border-solid border-[1.5px] border-black rounded-full py-2 px-4 ml-1.5 w-fit"
              data-testid="clearAll"
              onClick={clearAll}
            >
              Clear All Filters
            </button>
          </div>

          <div className="font-['Arial'] py-4 px-2">
            <span className="font-semibold mr-1">CIC/Desc:</span>
            {productDescription}
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  filterOptionsLoading: state.dashboard.filterOptionsLoading,
  dashboardFilters: state.dashboard.selectedFilterOptions,
  floatingResetKey: state.dashboard.floatingResetKey,
  filtersResetKey: state.dashboard.filtersResetKey,
  filtersUpdateKey: state.dashboard.filtersUpdateKey,
  filterOptions: state.dashboard.filterOptions,
  productDescription: state.dashboard.productDescription,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setDashboardFilters: (filters: IFilterOptions) =>
    dispatch(changeDashboardFilters(filters)),
  fetchFilterOptions: (type: string, query: string, filters: IFilterOptions) =>
    dispatch(fetchFilterOptions(type, query, filters)),
  fetchProductDescription: (cic: IFilterOption[]) =>
    dispatch(fetchProductDescription(cic)),
  fetchCICAndUPCDetails: (data: string, type: BarcodeType) =>
    dispatch(fetchCICAndUPCDetails(data, type)),
  resetExpandModeDCStoreFilters: () => dispatch(resetExpandModeDCStoreFilter()),
  resetExpandModeStoreOrderFilters: () =>
    dispatch(resetExpandModeStoreOrderFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
